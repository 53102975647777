import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../../containers";
import { Testimonials } from "../../components";
import { Container } from "./styled";
import styles from "../../styles/Home.module.scss";
import { useHistory } from "react-router-dom";

function Home({ setLanguage, language }) {
  const [jsonData, setJsonData] = useState(null);
  const history = useHistory();
  let path = window.location.hash;

  useEffect(() => {
    const fetchJsonData = async () => {
      if (language === "eng") {
        const response = await import("../../textContent/en.json");
        const data = await response.default;
        setJsonData(data);
      } else if (language === "it") {
        const response = await import("../../textContent/it.json");
        const data = await response.default;
        setJsonData(data);
      }
    };

    fetchJsonData();
  }, [language]);
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-active");
        }
      });
    });

    document.querySelectorAll(".box").forEach((box) => {
      observer.current.observe(box);
    });

    document.querySelectorAll(".side-box-contact").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".side-box").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".half-box").forEach((box) => {
      observer.current.observe(box);
    });
    return () => {
      observer.current.disconnect();
    };
  }, []);

  const handleScrollToContactForm = () => {
    if (window.location.hash === "#contactForm") {
      const contactForm = document.getElementById("contactForm");
      if (contactForm) {
        contactForm.scrollIntoView({ behavior: "smooth" });
      } else {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  };

  window.addEventListener("load", handleScrollToContactForm);

  return (
    <Layout setLanguage={setLanguage} jsonData={jsonData}>
      <Container>
        <div className="box hero-section">
          <div className="hero-section-content">
            <p>{jsonData?.home_hero_subtitle}</p>
            <h1 className={styles.dynamicTitle}>
              {jsonData?.home_hero_title_first_line}
              <br />
              {jsonData?.home_hero_title_second_line}
            </h1>
            <button
              className="btn-primary button"
              onClick={() =>
                window.scrollTo({
                  top: document.documentElement.scrollHeight,
                  behavior: "smooth",
                })
              }
            >
              {jsonData?.theme_page_book_button}
            </button>
          </div>
        </div>
        <div className="box servicis-section">
          <div className="service-item">
            <img
              className=""
              src="../../assets/wifi icon.svg"
              alt="Wifi icon"
            />
            <p>{jsonData?.home_servicies_first}</p>
          </div>
          <div className="service-item">
            <img
              className=""
              src="../../assets/balcony-icon.png"
              alt="Elevator"
            />
            <p>{jsonData?.home_servicies_second}</p>
          </div>
          <div className="service-item">
            <img
              className=""
              src="../../assets/duomo-icon.png"
              alt="Information icon"
            />
            <p>{jsonData?.home_servicies_third}</p>
          </div>
          <div className="service-item">
            <img
              className=""
              src="../../assets/stairs-icon.png"
              alt="Information icon"
            />
            <p>{jsonData?.home_servicies_fourth}</p>
          </div>
          <div className="service-item">
            <img
              className=""
              src="../../assets/information icon.svg"
              alt="Information icon"
            />
            <p>{jsonData?.home_servicies_fith}</p>
          </div>
          <div className="service-item">
            <img
              className=""
              src="../../assets/train-icon.png"
              alt="Information icon"
            />
            <p>{jsonData?.home_servicies_sixth}</p>
          </div>
          <p className="contact-us-text">{jsonData?.home_servicies_contact}</p>
        </div>
        <div className="box style-section">
          <div className="top-container">
            <p>{jsonData?.home_style_first_text}</p>
            <a className="theme-link" href="/style">
              {jsonData?.theme_learn_more_button}
            </a>
            <img
              className="statue-image"
              src="../../assets/statue image.png"
              alt="Cellini art work"
            />
          </div>
          <div className="half-box bottom-container">
            <img
              className="mobile-only"
              src="./assets/style background.jpg"
              alt="Cellini apartment style"
            />
            <div>
              <p className="title">{jsonData?.home_style_subtitle}</p>
              <h2 className={styles.dynamicTitleh2}>
                {jsonData?.home_style_title}
              </h2>
              <p>{jsonData?.home_style_second_text}</p>
              <button
                className="theme-link"
                style={{
                  background: "white",
                  border: "none",
                  paddingLeft: 0,
                }}
                onClick={() =>
                  window.scrollTo({
                    top: document.documentElement.scrollHeight,
                    behavior: "smooth",
                  })
                }
              >
                {jsonData?.theme_page_book_button}
              </button>
            </div>
          </div>
        </div>
        <div className="side-box experience-section">
          <img
            src="./assets/home-experience.png"
            alt="Cellini apartment style"
          />
          <div>
            <h2 className={styles.dynamicTitleh2}>
              {jsonData?.home_experience_title_first_line}
              <br />
              {jsonData?.home_experience_title_second_line}
            </h2>
            <p>{jsonData?.home_experience_text}</p>
            <button
              className="theme-link"
              style={{
                background: "white",
                border: "none",
                paddingLeft: 0,
              }}
              onClick={() =>
                window.scrollTo({
                  top: document.documentElement.scrollHeight,
                  behavior: "smooth",
                })
              }
            >
              {jsonData?.theme_page_book_button}
            </button>
          </div>
        </div>
        <div className="box half-box location-section">
          <img
            className="mobile-only"
            src="./assets/home-location.jpg"
            alt="Cellini apartment Duomo view"
          />
          <div>
            <p className="title">{jsonData?.home_location_subtitle}</p>
            <h2 className={styles.dynamicTitleh2}>
              {jsonData?.home_location_title}
            </h2>
            <p>{jsonData?.home_location_text}</p>
            <button
              className="theme-link"
              style={{
                background: "white",
                border: "none",
                paddingLeft: 0,
              }}
              onClick={() =>
                window.scrollTo({
                  top: document.documentElement.scrollHeight,
                  behavior: "smooth",
                })
              }
            >
              {jsonData?.theme_page_book_button}
            </button>
          </div>
        </div>
        {/* <Testimonials jsonData={jsonData} /> */}
        <div className="side-box-contact contact-section" id="contactForm">
          <div className="contact-info-container">
            <div className="contact-info-data">
              <h2 className={styles.dynamicTitleh2}>The Cellini Project</h2>
              <p>
                Piazza del Mercato Centrale 18,
                <br />
                50123 Firenze FL, <br />
                Italia
              </p>
            </div>
            <div className="contact-info-buttons">
              <a
                aria-label="Chat on WhatsApp"
                href="https://wa.me/393392264841"
                target="_blank"
              >
                <img
                  alt="Chat on WhatsApp"
                  src="./assets/WhatsAppButtonGreenLarge.svg"
                />
              </a>
              <div className="contact">
                <a
                  href="tel:+393392264841"
                  target="_blank"
                  style={{
                    color: "black",
                    fontWeight: 500,
                    fontFamily: "Gill Sans",
                    display: "flex",
                    textDecoration: "none",
                    fontSize: 16,
                  }}
                >
                  <img
                    className=""
                    src="../../assets/phone icon.svg"
                    alt="Phone icon"
                  />
                  <p>{jsonData?.theme_page_phone}</p>
                </a>
              </div>
              <div className="contact">
                <a
                  href="mailto:info@celliniproject.com"
                  target="_blank"
                  style={{
                    color: "black",
                    fontWeight: 500,
                    fontFamily: "Gill Sans",
                    display: "flex",
                    textDecoration: "none",
                    fontSize: 16,
                  }}
                >
                  <img
                    className=""
                    src="../../assets/mail icon.svg"
                    alt="Mail icon"
                  />
                  <p>{jsonData?.theme_page_mail}</p>
                </a>
              </div>
            </div>
            <div className="contact-info-messagge">
              <div className="title-section">
                <img
                  className=""
                  src="../../assets/information icon-b.svg"
                  alt="information icon"
                />
                <h3>{jsonData?.home_contact_subtitle}</h3>
              </div>
              <p>{jsonData?.home_contact_text}</p>
            </div>
          </div>
          <div className="contact-form-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2880.8059369894027!2d11.24917988702553!3d43.77688706990921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132a5402c961236b%3A0x53239ea86a2db7e6!2sPiazza%20del%20Mercato%20Centrale%2C%2018%2C%2050123%20Firenze%20FI%2C%20Italy!5e0!3m2!1sen!2sar!4v1707397490596!5m2!1sen!2sar"
              style={{ width: "100%", height: "90%", border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default Home;
