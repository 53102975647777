import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../../containers";
import { GetInTouch, ApartmentGallery, HotelSchema } from "../../components";
import { Container } from "./styled";
import styles from "../../styles/Home.module.scss";

function Apartments({ setLanguage, language }) {
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    const fetchJsonData = async () => {
      if (language === "eng") {
        const response = await import("../../textContent/en.json");
        const data = await response.default;
        setJsonData(data);
      } else if (language === "it") {
        const response = await import("../../textContent/it.json");
        const data = await response.default;
        setJsonData(data);
      }
    };

    fetchJsonData();
  }, [language]);
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-active");
        }
      });
    });

    document.querySelectorAll(".box").forEach((box) => {
      observer.current.observe(box);
    });

    document.querySelectorAll(".side-box-contact").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".side-box").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".half-box").forEach((box) => {
      observer.current.observe(box);
    });
    return () => {
      observer.current.disconnect();
    };
  }, []);

  const hotelData = {
    "@context": "https://schema.org",
    "@type": "Hotel",
    name: "Cellini project",
    description:
      "A beautifully located business hotel right in the heart of the alps. Watch the sun rise over the scenic Inn valley while enjoying your morning coffee.",
    address: {
      "@type": "PostalAddress",
      addressCountry: "IT",
      addressLocality: "Firenze",
      addressRegion: "Tuscany",
      postalCode: "50123",
      streetAddress: "Piazza del Mercato Centrale 18",
    },
    telephone: "+43 512 8000-0",
    photo: "http://www.acme-innsbruck.at//media/hotel_front.png",
    priceRange: "0",
  };
  return (
    <Layout setLanguage={setLanguage} isNavbarBlack={true} jsonData={jsonData}>
      <Container>
        <ApartmentGallery jsonData={jsonData} />
        <HotelSchema hotelData={hotelData} />
        <div className="apartment-description">
          <div className="header">
            <h2 className={styles.dynamicTitleh2}>{jsonData?.rooms_title}</h2>
            <div className="location">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 25.21 39.23"
                className="location-icon"
              >
                <title>Recurso 6100</title>
                <g id="Capa_2" data-name="Capa 2">
                  <g id="Home_v2" data-name="Home v2">
                    <path
                      class="cls-1"
                      d="M12.53,39.23l-1.39-2.46C9.59,34,4.34,24.72,2.64,21.61A21.5,21.5,0,0,1,0,12.5,12.67,12.67,0,0,1,12.53,0,12.7,12.7,0,0,1,25.21,12.5a21.63,21.63,0,0,1-2.62,9.11c-2.17,3.72-8.51,15.16-8.67,15.16Zm0-36h0A9.44,9.44,0,0,0,3.11,12.5a18.84,18.84,0,0,0,2.31,7.59C6.8,22.55,10.37,29,12.53,32.9c2.17-3.72,5.73-10.2,7.27-12.81A17.9,17.9,0,0,0,22,12.5a9.13,9.13,0,0,0-2.79-6.63,9.48,9.48,0,0,0-6.65-2.64Zm0,15.6h0a6.25,6.25,0,1,1,6.35-6.33,6.22,6.22,0,0,1-6.35,6.33Zm0-9.42h0a3.1,3.1,0,0,0,0,6.19,3.25,3.25,0,0,0,3.25-3.1,3.25,3.25,0,0,0-3.25-3.09Z"
                    />
                  </g>
                </g>
              </svg>
              <p>Piazza del Mercato Centrale 22</p>
            </div>
          </div>
          <div className="sub-header"></div>
          <div className="content">
            <p>{jsonData?.rooms_text_first}</p>
            <p>{jsonData?.rooms_text_second}</p>
            <p>{jsonData?.rooms_text_third}</p>
            <p>{jsonData?.rooms_text_fourth}</p>
            <p>{jsonData?.rooms_text_fifth}</p>
            <button
              className="btn-primary button"
              onClick={() =>
                window.scrollTo({
                  top: document.documentElement.scrollHeight,
                  behavior: "smooth",
                })
              }
            >
              {jsonData?.theme_page_book_button}
            </button>
          </div>
        </div>
        <div className="amenities">
          <h2 className={styles.dynamicTitleh2}>
            {jsonData?.rooms_amenities_title}
          </h2>
          <div className="amenities-container">
            <div className="service-item">
              <img src="../../assets/balconie-icon.svg" alt="Balconie icon" />
              <p>{jsonData?.rooms_amenities_boiler}</p>
            </div>
            <div className="service-item">
              <img
                className=""
                src="../../assets/microwave.svg"
                alt="Kitchen icon"
              />
              <p>{jsonData?.rooms_amenities_microwave}</p>
            </div>
            <div className="service-item">
              <img
                className=""
                src="../../assets/wachine-machine-icon.svg"
                alt="CWachine Machine icon"
              />
              <p>{jsonData?.rooms_amenities_washing_machine}</p>
            </div>
            <div className="service-item">
              <img
                className=""
                src="../../assets/oven.svg"
                alt="Information icon"
              />
              <p>{jsonData?.rooms_amenities_oven}</p>
            </div>
            <div className="service-item">
              <img
                className=""
                src="../../assets/moka.svg"
                alt="Information icon"
              />
              <p>{jsonData?.rooms_amenities_moka}</p>
            </div>
            <div className="service-item">
              <img
                className=""
                src="../../assets/hairdryer.svg"
                alt="Wifi icon"
              />
              <p>{jsonData?.rooms_amenities_hair_dryer}</p>
            </div>
            <div className="service-item">
              <img
                className=""
                src="../../assets/toaster.svg"
                alt="Elevator icon"
              />
              <p>{jsonData?.rooms_amenities_toaster}</p>
            </div>
            <div className="service-item">
              <img
                className=""
                src="../../assets/nespresso.svg"
                alt="Informaiton icon"
              />
              <p>{jsonData?.rooms_amenities_coffe}</p>
            </div>
            <div className="service-item">
              <img
                className=""
                src="../../assets/kitchen-icon.svg"
                alt="Fridge icon"
              />
              <p>{jsonData?.rooms_amenities_kitchen}</p>
            </div>
            <div className="service-item">
              <img
                className=""
                src="../../assets/dishwasher.svg"
                alt="Fridge icon"
              />
              <p>{jsonData?.rooms_amenities_dish_washer}</p>
            </div>
          </div>
        </div>
        <div className="about-house">
          <div>
            <h2 className={styles.dynamicTitleh2}>About house</h2>
            <p>
              <b>{jsonData?.rooms_amenities_location}: </b>Piazza del Mercato
              Centrale 22
            </p>
            <p>
              <b>{jsonData?.rooms_amenities_bedrooms}: </b>1
            </p>
            <p>
              <b>{jsonData?.rooms_amenities_guests}: </b>4
            </p>
            <p>
              <b>{jsonData?.rooms_amenities_bathdrooms}: </b>2
            </p>
            <p>
              <b>{jsonData?.rooms_amenities_property_size}: </b>74 mt2
            </p>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2880.8059369894027!2d11.24917988702553!3d43.77688706990921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132a5402c961236b%3A0x53239ea86a2db7e6!2sPiazza%20del%20Mercato%20Centrale%2C%2018%2C%2050123%20Firenze%20FI%2C%20Italy!5e0!3m2!1sen!2sar!4v1707397490596!5m2!1sen!2sar"
            style={{ height: "auto", border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <GetInTouch jsonData={jsonData} />
      </Container>
    </Layout>
  );
}

export default Apartments;
