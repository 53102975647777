import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  display: flex;
  margin: 15vh auto 2rem;
  height: 20vh;
  position: relative;
  .right-container,
  .left-container {
    width: 50%;
    img {
      width: 99%;
      height: 100%;
    }
  }
  .left-container {
    display: flex;
    flex-direction: column;
    .top-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      img {
        width: 49%;
      }
    }
    .bottom-container {
      overflow: hidden;
      img {
        width: 100%;
      }
    }
  }
  .showAll-btn {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 150px;
    border-radius: 24px;
    padding: 7px 15px;
    border: 1px solid black;
    margin: 0;
    cursor: pointer;
    p {
      margin: 0;
    }
  }
  .gallery-container {
    height: 100%;
    width: 100vw;
    position: absolute;
    background: #fff;
    z-index: 50;
    top: -10vh;
    left: 0;
    .gallery-header {
      height: 100px;
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        background: transparent;
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        cursor: pointer;
        svg {
          margin-right: 1rem;
        }
      }
    }
    .gallery-content {
      width: 80%;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      img {
        width: 49%;
        margin-bottom: 1rem;
      }
    }
  }
  @media (min-width: 867px) {
    height: 60vh;
  }
`;
export const GalleryContainer = styled.div`
  height: fit-content;
  min-height: 100vh;
  width: 100vw;
  position: absolute;
  background: #fff;
  z-index: 50;
  top: -10vh;
  left: 0;
  .gallery-header {
    height: 100px;
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      background: transparent;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      cursor: pointer;
      svg {
        margin-right: 1rem;
      }
    }
  }
  .gallery-content {
    width: 80%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    img {
      width: 49%;
      margin-bottom: 1rem;
    }
  }
`;
