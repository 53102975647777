import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../../containers";
import { Container } from "./styled";
import { GetInTouch } from "../../components";
import styles from "../../styles/Home.module.scss";
import { Link } from "react-router-dom";

function Offers({ setLanguage, language }) {
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    const fetchJsonData = async () => {
      if (language === "eng") {
        const response = await import("../../textContent/en.json");
        const data = await response.default;
        setJsonData(data);
      } else if (language === "it") {
        const response = await import("../../textContent/it.json");
        const data = await response.default;
        setJsonData(data);
      }
    };

    fetchJsonData();
  }, [language]);

  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-active");
        }
      });
    });

    document.querySelectorAll(".box").forEach((box) => {
      observer.current.observe(box);
    });

    document.querySelectorAll(".side-box-reversed").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".side-box").forEach((box) => {
      observer.current.observe(box);
    });
    document.querySelectorAll(".half-box").forEach((box) => {
      observer.current.observe(box);
    });
    return () => {
      observer.current.disconnect();
    };
  }, []);

  return (
    <Layout setLanguage={setLanguage} jsonData={jsonData} isNavbarBlack={true}>
      <Container>
        <div className="box  hero-section">
          <h2 className={styles.dynamicTitleh2}>
            {jsonData?.offers_title_text}
          </h2>
          <p> {jsonData?.offers_subtitle_text}</p>
        </div>
        <div className="content-container">
          <div className="card-container toScaleImage">
            <div className="data-container">
              <img src="./assets/offer-honeymoon.jpg" />
              <div className="content-container">
                <h3></h3>
                <p>Crafting wonderful experiences for you</p>
                <Link className="theme-link disabled" to="#">
                  COMING SOON
                </Link>
              </div>
            </div>
          </div>
          <div className="card-container toScaleImage">
            <div className="data-container ">
              <img src="./assets/cooking-class.jpg" />
              <div className="content-container">
                <h3></h3>
                <p>Crafting wonderful experiences for you</p>
                <Link className="theme-link disabled" to="#">
                  COMING SOON
                </Link>
              </div>
            </div>
          </div>
          <div className="card-container toScaleImage">
            <div className="data-container">
              <img src="./assets/offer-wine.jpg" />
              <div className="content-container">
                <h3></h3>
                <p>Crafting wonderful experiences for you</p>
                <Link className="theme-link disabled" to="#">
                  COMING SOON
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default Offers;
